import { useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { FeatureFlag } from '../store/featureStore/enums/FeatureFlag';
import { useFeatureFlag } from '../store/featureStore/hooks/useFeatureFlag';
import useNewLoanDocumentStore from '../store/loanDocumentStore/useNewLoanDocumentStore';

/**
 * LoanDocumentGuard
 *
 * The purpose of this guard is to prevent the user from accessing any particular loan document route
 * based on a condition that has not been met.
 *
 * Because the Loan Document routing is handled outside of our typical workflow system, we utilize this guard
 * instead of the WorkflowGuard.
 */
export const LoanDocumentGuard = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const location = useLocation();

  const {
    setWorkflowAsStarted,
    computed: { hasNotProvidedDocuments },
  } = useNewLoanDocumentStore();

  const isLoanDocumentMileagePage = useMemo(() => {
    return location.pathname.includes('/loan-documents/current-mileage');
  }, [location.pathname]);

  const unallowedMileagePageRedirect = useFeatureFlag(
    FeatureFlag.ENABLE_SIMPLIFIED_LOAN_DOCUMENTS_WORKFLOW,
  )
    ? '/loan-documents/document-upload'
    : '/loan-documents/list';

  // start the workflow when the user navigates to a loan document route.
  // this will ensure that the dashboard displays a vehicle card to access the loan document workflow.
  useEffect(() => {
    setWorkflowAsStarted();
  }, []);

  // redirects in the following cases:
  // - the user has not provided any documents and tries to access the mileage page.
  // - the mileage page was refreshed and provided documents are no longer available. (we dont retain provided documents on refresh)
  if (isLoanDocumentMileagePage && hasNotProvidedDocuments) {
    return <Navigate to={unallowedMileagePageRedirect} replace />;
  }

  return children;
};
