import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { thirdPartyStore } from './thirdPartyStore';
import { ThirdPartyState } from './types/ThirdPartyState';

const useThirdPartyStore: DevtoolsStore<ThirdPartyState> = create()(
  devtools<ThirdPartyState>(
    thirdPartyStore({
      useApiStore: lazyDependency(() => useApiStore),
    }),
    devToolOptions('thirdPartyStore'),
  ),
);

export default useThirdPartyStore;
