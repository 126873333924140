import { EmbeddedInsurance } from '@ei-tech/embedded-component';
import { ApplicationType } from '@thesavingsgroup/enums/ApplicationType';
import { isNonEmptyString } from '@thesavingsgroup/utils/isNonEmptyString';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { isApplicationActive } from '../../../../common';
import { ApplicationProgress } from '../../../../components/ApplicationProgress/ApplicationProgress';
import { RemoveVehicle } from '../../../../components/RemoveVehicle/RemoveVehicle';
import { SelectedOfferContact } from '../../../../components/SelectedOfferContact/SelectedOfferContact';
import ResourceAction from '../../../../components/shared/ResourceAction/ResourceAction';
import { ResourcePanel } from '../../../../components/shared/ResourcePanel/ResourcePanel';
import { ResourcesContainer } from '../../../../components/shared/ResourcePanel/styles';
import VehicleValue from '../../../../components/VehicleValue/VehicleValue';
import { useCurrentUserContext } from '../../../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { SearchParamKey } from '../../../../services/urlParams/urlParams';
import { urlParamHook } from '../../../../services/urlParams/urlParamsService';
import { allowMultipleVehicles } from '../../../../services/workflow/workflowService';
import useLoanDocumentStore from '../../../../store/_legacy/loanDocumentsStore';
import { FeatureFlag } from '../../../../store/featureStore/enums/FeatureFlag';
import { useFeatureFlag } from '../../../../store/featureStore/hooks/useFeatureFlag';
import { resetStore } from '../../../../store/storeManager/storeManager';
import useThirdPartyStore from '../../../../store/thirdPartyStore/useThirdPartyStore';
import { ApplicationDataInterface } from '../../applicationData.interface';
import { ApplicationSummary } from './ApplicationSummary';
import { ContentBlock, ContentTitle, ContentTitleContainer } from './styles';

const ApplicationResources = ({
  applicationData,
  openSummary,
}: {
  applicationData: ApplicationDataInterface;
  openSummary: boolean;
}) => {
  const navigate = useNavigate();
  const workflowName = useWorkflowPathname();
  const { getEmbeddedInsuranceContent, getEmbeddedInsuranceURL } =
    useThirdPartyStore.getState();

  const [currentUserContext] = useCurrentUserContext();
  const { currentVehicle } = applicationData || {};

  const vehicleId = get(currentVehicle, 'id');
  const autopayNumber = get(currentVehicle, 'checklightSession.autopayNumber');
  const applicationType = get(currentVehicle, 'checklightSession.type');
  const embeddedInsuranceLeadId = get(currentVehicle, 'leadId');

  const applicationTypeRefinance =
    applicationType === ApplicationType.REFINANCE;

  const applicationActive = isApplicationActive(
    currentVehicle?.checklightSession,
  );

  const residenceState = get(
    currentUserContext,
    'residences.current.physicalAddress.province',
  );

  const { savedParam: showProgressModal } = urlParamHook(
    SearchParamKey.showProgressModal,
  );

  const showVehicleValuePanel = useFeatureFlag(
    FeatureFlag.ENABLE_VEHICLE_VALUATION_SECTION,
  );

  const showOfferSummaryPanel = useFeatureFlag(
    FeatureFlag.ENABLE_OFFER_SUMMARY_MODAL,
  );

  const showLoanDocumentsAction = useFeatureFlag(
    FeatureFlag.ENABLE_LOAN_DOCUMENTS_MODULE,
  );

  const showEmbeddedInsuranceComponent =
    useFeatureFlag(FeatureFlag.ENABLE_EMBEDDED_INSURANCE_COMPONENT, {
      residenceState,
    }) && isNonEmptyString(embeddedInsuranceLeadId);

  const resourceActionClicked = () => {
    const {
      computed: { currentVehicleId },
    } = useLoanDocumentStore.getState();

    if (vehicleId !== currentVehicleId) {
      // Reset the store if the application data has changed
      // This is to ensure that the store is in a clean state when viewing a different application
      resetStore(useLoanDocumentStore);

      useLoanDocumentStore.setState({ applicationData });
    }

    navigate(`/${workflowName}/loan-documents/list`);
  };

  return (
    <ContentBlock>
      <ContentTitleContainer>
        <ContentTitle>Application resources</ContentTitle>
      </ContentTitleContainer>
      {showEmbeddedInsuranceComponent && (
        <EmbeddedInsurance
          getContent={getEmbeddedInsuranceContent}
          getUrl={getEmbeddedInsuranceURL}
          leadId={embeddedInsuranceLeadId}
          pollingInterval={20000}
        />
      )}

      {showLoanDocumentsAction && applicationTypeRefinance && (
        <ResourceAction
          actionTitle={'Get started'}
          subtitle={
            'Upload your loan documents from the dealership and let TSG find you more ways to save. Average customers get over $1,000 refunded when they refinance!'
          }
          title={'Unlock more ways to Save!'}
          onClick={resourceActionClicked}
        />
      )}

      <ResourcesContainer>
        {autopayNumber && showOfferSummaryPanel && applicationActive ? (
          <ApplicationSummary
            autopayNumber={autopayNumber}
            openSummary={openSummary}
            vehicleId={vehicleId}
          />
        ) : null}
        <ResourcePanel
          shouldInstantlyOpen={Boolean(showProgressModal)}
          subtitle="Your application progress"
          title="Progress"
        >
          <ApplicationProgress application={applicationData} />
        </ResourcePanel>

        {showVehicleValuePanel && (
          <ResourcePanel
            subtitle="Discover your vehicle's value"
            title="Vehicle value"
          >
            <VehicleValue vehicle={currentVehicle} />
          </ResourcePanel>
        )}

        <ResourcePanel subtitle="We’re always here to help" title="Contact">
          <SelectedOfferContact
            applicationBranding={applicationData.branding}
            autopayNumber={autopayNumber}
            checklightSession={currentVehicle.checklightSession}
            vehicleId={vehicleId}
          />
        </ResourcePanel>
        {allowMultipleVehicles(workflowName) ? (
          <ResourcePanel
            subtitle="Remove from dashboard"
            testId="vehicleResource"
            title="Remove Vehicle"
          >
            <RemoveVehicle vehicle={currentVehicle} />
          </ResourcePanel>
        ) : null}
      </ResourcesContainer>
    </ContentBlock>
  );
};

export default ApplicationResources;
