import { fromPairs, get as lodashGet, isEmpty, map } from 'lodash';
import { StateCreator } from 'zustand';

import { FeatureFlag } from './enums/FeatureFlag';
import { buildContextWithKey } from './functions/buildContextWithKey';
import { FeatureResponse } from './types/FeatureResponse';
import { FeatureState } from './types/FeatureState';
import { FeatureStoreDependencies } from './types/FeatureStoreDependencies';

export const featureStore =
  ({
    appName,
    featureURL,
    useApiStore,
  }: FeatureStoreDependencies): StateCreator<FeatureState> =>
  (set, get) => ({
    // Shared context that is merged into every feature flag request (e.g., userId)
    defaultContext: {},

    // Cache of feature flags, keyed by the stringified context object
    featureFlags: {},

    // Cache of the last interacted flags
    lastInteractedFlags: {},

    // Map of pending requests, keyed by the stringified context object
    // Used to avoid duplicate requests for the same context
    pendingRequests: new Map<string, Promise<void>>(),

    // Time-to-live for cached feature flags (in milliseconds)
    ttl: 60_000,

    // Tracks how many times each feature flag was evaluated as enabled (yes) or disabled (no)
    usageMetrics: {},

    // The interval (in milliseconds) at which usageMetrics should be reported
    usageMetricsInterval: 60_000,

    // Allows adding new fields to the default context (e.g., add userId after login)
    addDefaultContextField: (key: string, value: unknown) => {
      set((state) => ({
        defaultContext: { ...state.defaultContext, [key]: value },
      }));
    },

    // Fetches feature flags for a given context and caches the result
    getFeatureFlags: (context = {}) => {
      const {
        defaultContext,
        featureFlags,
        pendingRequests,
        incrementUsageMetric,
      } = get();

      // Merge default context with any additional provided context
      // to generate a cache key for the merged context
      const [mergedContext, contextKey] = buildContextWithKey(
        defaultContext,
        context,
      );

      // Avoid duplicate API requests for the same contextKey
      if (pendingRequests.has(contextKey)) {
        return pendingRequests.get(contextKey);
      }

      const fetchPromise = useApiStore
        .getState()
        .featureApi()
        .post<never, FeatureResponse>(featureURL, {
          context: mergedContext,
        })
        .then(({ toggles }) => {
          const previousFlags = lodashGet(
            featureFlags,
            [contextKey, 'flags'],
            {},
          );

          const flags = fromPairs(
            map(toggles, ({ name, enabled }) => [name, enabled]),
          );

          // The api endpoint returns all flags that are enabled for the given context
          // so we need to detect flags that were previously present but are now missing
          const removedFlags = Object.keys(previousFlags).filter(
            (flag): flag is FeatureFlag => !(flag in flags),
          );

          // Update the usage metrics for each of the removed (disabled) flags
          removedFlags.forEach((flag) => {
            incrementUsageMetric(flag, false);
          });

          set((state) => ({
            featureFlags: {
              ...state.featureFlags,

              [contextKey]: {
                flags,
                timestamp: Date.now(),
              },
            },
          }));
        })
        .finally(() => {
          pendingRequests.delete(contextKey);
        });

      pendingRequests.set(contextKey, fetchPromise);

      return fetchPromise;
    },

    // Increments the usage count for a specific feature flag based on its value.
    // Called whenever a flag is checked via `isEnabled` or `getFeatureFlags`.
    incrementUsageMetric: (flag: FeatureFlag, enabled: boolean) => {
      set((state) => {
        const current = state.usageMetrics[flag] ?? { no: 0, yes: 0 };

        return {
          usageMetrics: {
            ...state.usageMetrics,

            [flag]: {
              no: !enabled ? current.no + 1 : current.no,
              yes: enabled ? current.yes + 1 : current.yes,
            },
          },
        };
      });
    },

    isEnabled: (flag: FeatureFlag, context = {}) => {
      const { defaultContext, featureFlags, ttl, getFeatureFlags } = get();

      // Determine the key for the given context
      const [, contextKey] = buildContextWithKey(defaultContext, context);

      // Retrieve the flags from cache if available
      const cached = featureFlags[contextKey];

      // Determine if the cached flags are stale
      const isStale = cached && Date.now() - cached.timestamp > ttl;

      // If flags for this context haven't been loaded yet or have expired,
      // trigger fetch in the background
      if (!cached || isStale) {
        getFeatureFlags(context);
      }

      // If we have flags for this context, return the value of the given flag
      if (lodashGet(cached, 'flags')) {
        return cached.flags[flag] ?? false;
      }

      // Fallback to false if we have no flags at all
      return false;
    },

    reportMetrics: () => {
      const { usageMetrics, usageMetricsInterval } = get();

      if (isEmpty(usageMetrics)) {
        return;
      }

      const start = new Date();
      const stop = new Date(start.getTime() - usageMetricsInterval);

      const featureHttpRequest = useApiStore.getState().featureApi();

      return featureHttpRequest
        .post<never, void>(`${featureURL}/client/metrics`, {
          appName,
          bucket: {
            start,
            stop,
            toggles: usageMetrics,
          },
        })
        .then(() => set({ usageMetrics: {} }))
        .catch((error) => {
          console.error(error);
        });
    },
  });
