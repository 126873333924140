import { isBoolean } from 'lodash';
import { useEffect } from 'react';

import { FeatureFlag } from '../enums/FeatureFlag';
import { buildContextWithKey } from '../functions/buildContextWithKey';
import useFeatureStore from '../useFeatureStore';

/**
 * Hook to get the enabled state of a specific feature flag.
 *
 * This hook wraps the `useFeatureStore` and returns the current value of a feature flag.
 * It automatically subscribes the component to updates for the given flag so the component
 * re-renders when the flag's state changes.
 *
 * This hook will also increment the usage metric for the given flag based on the enabled state and
 * update the `lastInteractedFlags` in the store to track the most recently used feature flags.
 *
 */
export const useFeatureFlag = (
  flag: FeatureFlag,
  context: Record<string, unknown> = {},
): boolean | undefined => {
  const isEnabled = useFeatureStore((state) => state.isEnabled(flag, context));

  useEffect(() => {
    const {
      defaultContext,
      featureFlags,
      lastInteractedFlags,
      incrementUsageMetric,
    } = useFeatureStore.getState();

    // Retrieve the cached flags for this contextKey
    const [, contextKey] = buildContextWithKey(defaultContext, context);
    const cached = featureFlags[contextKey];

    // If we haven't fetched flags for this context yet, do nothing
    if (!cached || !cached.flags) {
      return;
    }

    // Increment usage metric for the given flag based on the current state
    const isFlagEnabled = cached.flags[flag];
    if (isBoolean(isFlagEnabled)) {
      incrementUsageMetric(flag, isFlagEnabled);
    }

    // Update lastInteractedFlags in store if it's not already the current one
    // This allows tracking the most recently used feature flags for metrics, debugging, or error reporting
    if (lastInteractedFlags !== cached.flags) {
      useFeatureStore.setState({ lastInteractedFlags: cached.flags });
    }
  }, [flag, context, isEnabled]);

  return isEnabled;
};
