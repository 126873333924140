export enum FeatureFlag {
  ENABLE_REFANDI_SIGN_UP_COPY = 'EnableRefandiSignUpCopy',
  ENABLE_REFANDI_HIDDEN_FEATURES = 'EnableRefandiHiddenFeatures',
  ENABLE_LOAN_DOCUMENTS_MODULE = 'EnableLoanDocumentsModule',
  ENABLE_OFFER_SUMMARY_MODAL = 'EnableOfferSummaryModal',
  ENABLE_PLATE_TO_VIN_SERVICE = 'EnablePlateToVinService',
  ENABLE_SIMPLIFIED_LOAN_DOCUMENTS_WORKFLOW = 'EnableSimplifiedLoanDocumentsWorkflow',
  ENABLE_VEHICLE_VALUATION_SECTION = 'EnableVehicleValuationSection',
  ENABLE_EMBEDDED_INSURANCE_COMPONENT = 'EnableEmbeddedInsuranceComponent',
}
