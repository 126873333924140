import './App.css';
import './i18n/i18n';

import * as Sentry from '@sentry/react';
import { flow } from 'lodash';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import { ModalsScrollWrapper } from './components/ModalsScrollWrapper/ModalsScrollWrapper';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import ErrorBoundary from './components/shared_new/RouteError/ErrorBoundary';
import { SignOutListener } from './components/SignOutListener/SignOutListener';
import { withApiContextProvider } from './providers/context/ApiContext/withApiContextProvider';
import { withCurrentUserContextProvider } from './providers/context/CurrentUserContext/withCurrentUserContextProvider';
import { withDynamicBrandingProvider } from './providers/context/DynamicBrandingProvider/withDynamicBrandingProvider';
import { withErrorReportingContextProvider } from './providers/context/ErrorReportingContext/withErrorReportingContextProvider';
import { withMatchMediaProvider } from './providers/context/MatchMediaProvider/withMatchMediaProvider';
import { withModalContextProvider } from './providers/context/ModalContextProvider/withModalContextProvider';
import { withStyletronProvider } from './providers/context/StyletronProvider/withStyletronProvider';
import { withWorkflowInitialContextProvider } from './providers/context/WorkflowInitialContext/withWorkflowInitialContextProvider';
import { routes } from './routes/ChecklightRouter';

const AppWithContextProviders = flow([
  withModalContextProvider,
  withStyletronProvider,
  withCurrentUserContextProvider,
  withApiContextProvider,
  withDynamicBrandingProvider,
  withErrorReportingContextProvider,
  withWorkflowInitialContextProvider,
  withMatchMediaProvider,
])(App);

function App() {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    {
      element: <AppAfterRouter />,
      errorElement: <ErrorBoundary />,
      children: routes,
    },
  ]);

  return <RouterProvider router={router} />;
}

const AppAfterRouter = () => {
  return (
    <ScrollToTop>
      <ModalsScrollWrapper>
        <SignOutListener />
        <Outlet />
      </ModalsScrollWrapper>
    </ScrollToTop>
  );
};

export default AppWithContextProviders;
