import type { Content } from '@ei-tech/embedded-component';
import { StateCreator } from 'zustand';

import { config } from '../../config';
import { ThirdPartyState } from './types/ThirdPartyState';
import { ThirdPartyStoreDependencies } from './types/ThirdPartyStoreDependencies';

export const thirdPartyStore =
  ({
    useApiStore,
  }: ThirdPartyStoreDependencies): StateCreator<ThirdPartyState> =>
  (set, get) => ({
    computed: {
      get legacyRequestConfig() {
        // This is used to set the token header for use within the legacy UI
        const token = localStorage.getItem(config.session.CHECKLIGHT_TOKEN_KEY);
        return token ? { headers: { 'X-Access-Token': token } } : {};
      },
    },

    getEmbeddedInsuranceContent: (leadId: string) => {
      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .post<never, Content>(
          '/2.0/third-party/embedded-insurance/content',
          { leadId },
          get().computed.legacyRequestConfig,
        )
        .then((content) => content);
    },
    getEmbeddedInsuranceURL: (leadId: string) => {
      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .post<never, { url: string }>(
          '/2.0/third-party/embedded-insurance/link',
          { leadId },
          get().computed.legacyRequestConfig,
        )
        .then(({ url }) => url);
    },
  });
