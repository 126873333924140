import { AuthType } from '@thesavingsgroup/auth/AuthType';
import { DeliveryMechanism } from '@thesavingsgroup/auth/DeliveryMechanism';
import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';
import { merge } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { PillowForm } from '../../components/PillowForm';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useCountdownTimer } from '../../hooks/useCountdownTimer/useCountdownTimer';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useErrorReporting } from '../../hooks/useErrorReporting/useErrorReporting';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { ApiError } from '../../providers/context/ApiContext/withApiContextProvider';
import { UserContextT } from '../../providers/context/CurrentUserContext/CurrentUserContextT';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import { phoneNumberFormatter } from '../../utils/pipes/phone-number';
import model from './Model';

type ModelFragment = {
  fieldErrors: { [keys: string]: string };
  globalErrors: Array<string>;
};

const mapErrorsToFormFields = (apiErrors: Array<ApiError>) => {
  const modelFragment = { fieldErrors: {}, globalErrors: [] } as ModelFragment;
  apiErrors.forEach((error: any) => {
    if (
      [HttpStatusCode.NOT_FOUND, HttpStatusCode.UNAUTHORIZED].includes(
        error.statusCode,
      )
    ) {
      modelFragment.globalErrors.push(
        'There was an error attempting to verify the code. Please click "Re-send" and try again.',
      );
    } else if (
      error.statusCode === HttpStatusCode.SERVER_ERROR &&
      error.message.startsWith('Session must be initialized')
    ) {
      modelFragment.globalErrors.push(
        'There was an error attempting to verify the code. Please re-send it.',
      );
    } else {
      modelFragment.globalErrors.push(
        'There was an unexpected error attempting to verify the code. Please try again.',
      );
    }
  });
  return modelFragment;
};

const Controller = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const rootPath = useWorkflowPathname();
  const { captureException } = useErrorReporting();
  const { savedParam: leadChannelCode } = urlParamHook(
    SearchParamKey.leadChannelCode,
  );
  const { savedParam: collectApplicantAgreeToTerms } = urlParamHook(
    SearchParamKey.collectAgreeToTerms,
  );
  const { savedParam: collectCoApplicantAgreeToTerms } = urlParamHook(
    SearchParamKey.coApplicantCollectAgreeToTerms,
  );
  const { savedParam: authType } = urlParamHook(SearchParamKey.authType);
  const { savedParam: vehicleId } = urlParamHook(SearchParamKey.vehicleId);
  const shouldSaveApplicantAgreeToTerms =
    collectApplicantAgreeToTerms === 'true';
  const shouldSaveCoApplicantAgreeToTerms =
    collectCoApplicantAgreeToTerms === 'true';

  const redirectToDashboard = useCallback(
    () => navigate(`/${rootPath}/dashboard`, { replace: true }),
    [navigate, rootPath],
  );

  const redirectToSignOutMaxAttempts = () =>
    navigate(`/${rootPath}/sign-out/`, {
      state: {
        manually: true,
        globalErrors: [
          'Your account has reached the maximum number of failed login attempts and has been temporarily locked. Please try again in 15 minutes.',
        ],
      },
    });

  const api = useAPI();

  const methods = useForm();
  const { resetField, clearErrors } = methods;

  const [secondsLeft, setSecondsLeft] = useState(0);
  const { resendTimerDuration = 15 } = model;
  const { startTimer, stopTimer } = useCountdownTimer(
    resendTimerDuration,
    setSecondsLeft,
  );

  const { primaryPhoneNumber } = location.state as any;
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();

  useEffect(() => {
    if (!transformedPhoneNumber) {
      window.history.back();
    }
  }, []);

  useEffect(() => {
    startTimer();
    return () => stopTimer();
  }, [startTimer, stopTimer]);

  const saveUserAgreement = useAsyncCallback(async () => {
    if (shouldSaveApplicantAgreeToTerms || shouldSaveCoApplicantAgreeToTerms) {
      await api.post('/users/current/agree-to-terms', {
        termsAndConditionsOptIn: {
          agreesToTerms: true,
        },
        vehicleId,
      });
      setCurrentUserContext({
        ...currentUserContext,
        termsAndConditionsOptIn: {
          agreesToTerms: true,
        },
      });
    }
  });

  const onSubmit = useAsyncCallback(async ({ pin }) => {
    if (primaryPhoneNumber) {
      onResend.reset();
      try {
        const newUserContext: UserContextT = await api.post(
          '/users/authenticate',
          {
            authType,
            pin,
            primaryPhoneNumber,
            vehicleId,
          },
          {
            params: {
              includeUser: true,
              leadChannelCode,
            },
          },
        );
        setCurrentUserContext(newUserContext);
      } catch (e: any) {
        if (
          e.find((error: any) => error.statusCode === HttpStatusCode.FORBIDDEN)
        ) {
          redirectToSignOutMaxAttempts();
        }

        throw mapErrorsToFormFields(e as Array<ApiError>).globalErrors;
      }

      try {
        await saveUserAgreement.execute();
      } catch (e) {
        // Ignore errors and sign in
        // Send error to Sentry
        captureException(e);
      }

      redirectToDashboard();
    }
  });

  const onResend = useAsyncCallback(async () => {
    if (primaryPhoneNumber) {
      resetField('pin');
      startTimer();
      try {
        onSubmit.reset();
        await api.post('pin/deliver', {
          purpose: authType || AuthType.ACCOUNT_SIGN_IN_USING_PIN,
          deliverWith: DeliveryMechanism.SMS,
          phone: primaryPhoneNumber,
        });
        clearErrors('pin');
      } catch (e: any) {
        if (
          e.find((error: any) => error.statusCode === HttpStatusCode.FORBIDDEN)
        ) {
          redirectToSignOutMaxAttempts();
        }

        /*eslint-disable-next-line no-throw-literal*/
        throw [
          'There was an unexpected error attempting to send the verification code, please try again later.',
        ];
      }
    }
  });
  const transformedPhoneNumber = useMemo(
    () => primaryPhoneNumber && phoneNumberFormatter(primaryPhoneNumber),
    [primaryPhoneNumber],
  );
  const enhancedProps = useMemo(() => {
    const timerIsActive = secondsLeft > 0;
    const loading = onSubmit.loading || onResend.loading;
    return merge(
      {},
      props,
      { presModel: model },
      {
        presModel: {
          template: {
            header: {
              showBack: !loading && !timerIsActive,
              onBack: () => window.history.back(),
            },
          },
          form: {
            actions: {
              primary: {
                handler: onSubmit.execute,
                isDisabled: loading,
              },
              secondary: {
                handler: onResend.execute,
                isDisabled: loading || timerIsActive,
                timerDuration: timerIsActive && secondsLeft,
                ...(timerIsActive ? { label: 'Retry' } : {}),
              },
            },
            fields: {
              pin: {
                hint: loading
                  ? 'Verifying code...'
                  : `Code sent to ${transformedPhoneNumber}`,
                disabled: loading,
              },
            },
            globalErrors: onResend.error || onSubmit.error,
          },
        },
      },
    );
  }, [props, onSubmit, onResend, transformedPhoneNumber, secondsLeft]);

  return <PillowForm {...enhancedProps} methods={methods} />;
};

Controller.displayName = 'SignInVerifyPin.Controller';
export default Controller;
