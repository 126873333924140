import { getContextKey } from './getContextKey';

export const buildContextWithKey = (
  defaultContext: Record<string, unknown>,
  overrideContext: Record<string, unknown>,
): [Record<string, unknown>, string] => {
  const merged = { ...defaultContext, ...overrideContext };

  // Remove timestamp from context before generating key
  const contextForKey = Object.fromEntries(
    Object.entries(merged).filter(([key]) => key !== 'timestamp'),
  );

  return [merged, getContextKey(contextForKey)];
};
