import { devtools } from 'zustand/middleware';

import { config } from '../../config';
import useApiStore from '../apiStore/useApiStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { featureStore } from './featureStore';
import { FeatureState } from './types/FeatureState';

const useFeatureStore: DevtoolsStore<FeatureState> = create()(
  devtools<FeatureState>(
    featureStore({
      appName: config.unleash.appName,
      featureURL: config.unleash.url,
      useApiStore: lazyDependency(() => useApiStore),
    }),
    devToolOptions('featureStore'),
  ),
);

export default useFeatureStore;
